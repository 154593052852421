import Vue from 'vue';
import Vuex from 'vuex';
import { cloneDeep, delay, merge } from 'lodash';
import createPersistedState from 'vuex-persistedstate';
import { ROLES } from '@/core/dict/role-dict';
import defaultApiResultManager from '@/core/shared/defaultApiResultManager';
import AuthService from '@/service/AuthService';
import GeolocationService from '@/service/GeolocationService';
import { PassengerMapper } from '@/shared/mappers/passenger.mapper';
import UserService from '@/service/UserService';
import { UserMapper } from '@/shared/mappers/user.mapper';
import { ABSTRACT_PLACE_TYPES } from '@/core/dict/place-type-dict';
import CompanyModule from './modules/comapny';
import InvoiceModule from './modules/invoice';
import StartingBaseModule from './modules/starting-base';
import PlaceModule from './modules/place';
import TaxiCourseModule from './modules/taxi-course';
import LocomotiveModule from './modules/locomotive';
import EmployeeRequestModule from './modules/employee-request';
import CourseSettingsModule from './modules/course-settings';
import CourseCreationModule from './modules/course-creation';
import KilometerCourseModule from './modules/kilometer-course';
import ServerSentEventsModule from './modules/server-sent-events';
import CommentModule from './modules/comment';
import TransferRideModule from './modules/transfer-ride';
import DriverModule from './modules/driver';
import PassengerModule from './modules/passenger';
import EmployeeGroup from './modules/employee-group';
import CarModule from './modules/car';
import TransferProtocolModule from './modules/transfer-protocol';
import FinSettlementModule from './modules/fin-settlement';

Vue.use(Vuex);

const taxiCourseFiltersPersister = createPersistedState({
  paths: ['taxiCourse.selectedFiltersTab', 'taxiCourse.filters', 'taxiCourse.searchTerm'],
});

const storeConfig = () =>
  cloneDeep({
    state: {
      sidebar: window.innerWidth >= 1264,
      user: false,
      passengerList: [],
      passengerListLoading: false,
      abstractPlacesList: [],
      abstractPlacesListLoading: false,
      hotelList: [],
      hotelListLoading: false,
      companiesList: [],
      companiesListLoading: false,
      railwayCompanies: [],
      railwayCompaniesLoading: false,
      loggedCompany: null,
      loggedCompanyLoading: false,
      startingBasesList: [],
      startingBasesListLoading: false,
      position: {
        latitude: null,
        longitude: null,
      },
      isUserPositionLoading: false,
      usersList: [],
      usersListLoading: false,
      isLoading: false,
    },

    plugins: [taxiCourseFiltersPersister],

    mutations: {
      setSidebar: (state, payload) => {
        state.sidebar = payload;
      },
      setUser: (state, payload) => {
        state.user = payload !== null ? UserMapper.toModel(payload) : null;
      },

      setPassengerList: (state, payload) => {
        state.passengerList = payload.map(PassengerMapper.toModel);
      },

      addPassenger: (state, payload) => {
        state.passengerList.push(payload);
      },

      setPassengerListLoading: (state, payload) => {
        state.passengerListLoading = payload;
      },

      setAbstractPlacesList: (state, payload) => {
        state.abstractPlacesList = payload;
      },

      addAbstractPlace: (state, payload) => {
        state.abstractPlacesList = [...state.abstractPlacesList, payload];
      },

      updateAbstractPlace: (state, payload) => {
        const placeIdx = state.abstractPlacesList.findIndex((place) => place.id === payload.id);
        state.abstractPlacesList.splice(placeIdx, 1, payload);
      },

      setAbstractPlacesListLoading: (state, payload) => {
        state.abstractPlacesListLoading = payload;
      },

      removeAbstractPlace: (state, payload) => {
        state.abstractPlacesList = state.abstractPlacesList.filter((place) => place.id !== payload.id);
      },

      setHotelList: (state, payload) => {
        state.hotelList = payload;
      },

      setHotelListLoading: (state, payload) => {
        state.hotelListLoading = payload;
      },

      setCompaniesList: (state, payload) => {
        state.companiesList = payload;
      },

      addTransportCompany: (state, payload) => {
        state.companiesList = [...state.companiesList, payload];
      },

      setCompaniesListLoading: (state, payload) => {
        state.companiesListLoading = payload;
      },

      setRailwayCompanies: (state, payload) => {
        state.railwayCompanies = payload;
      },

      setRailwayCompaniesLoading: (state, payload) => {
        state.railwayCompaniesLoading = payload;
      },

      updateRailwayCompany: (state, payload) => {
        const haystackIdx = state.railwayCompanies.findIndex((company) => company.id === payload.id);

        if (haystackIdx !== -1) {
          state.railwayCompanies.splice(haystackIdx, 1, payload);
        } else {
          state.railwayCompanies.push(payload);
        }
      },

      setLoggedCompany: (state, payload) => {
        state.loggedCompany = payload;
      },

      setLoggedCompanyLoading: (state, payload) => {
        state.loggedCompanyLoading = payload;
      },

      updateContractorsOfPrincipal: (state, updatedPrincipal) => {
        state.loggedCompany = {
          ...state.loggedCompany,
          principals: state.loggedCompany.principals.map((companyContractor) => {
            if (companyContractor.principal.id === updatedPrincipal.id) {
              return {
                ...companyContractor,
                principal: {
                  ...companyContractor.principal,
                  contractors: updatedPrincipal.contractors,
                },
              };
            }

            return companyContractor;
          }),
        };
      },

      addRailwayCompany: (state, payload) => {
        state.railwayCompanies = [...state.railwayCompanies, payload];
      },

      setStartingBasesList: (state, payload) => {
        state.startingBasesList = payload;
      },

      setStartingBasesListLoading: (state, payload) => {
        state.startingBasesListLoading = payload;
      },

      updatePassenger: (state, payload) => {
        const passengerIdx = state.passengerList.findIndex(({ id }) => id === payload.id);

        if (passengerIdx !== -1) {
          state.passengerList.splice(passengerIdx, 1, payload);
        }
      },
      removePassenger: (state, payload) => {
        state.passengerList = state.passengerList.filter((passenger) => passenger.id !== payload.id);
      },

      saveUserGeolocation: (state, { coords }) => {
        state.position = {
          latitude: coords.latitude,
          longitude: coords.longitude,
        };
      },
      setUserPositionLoading: (state, val) => {
        state.isUserPositionLoading = val;
      },
      setUsersList: (state, val) => {
        state.usersList = val;
      },
      setUsersListLoading: (state, val) => {
        state.usersListLoading = val;
      },
      setLoading: (state, val) => {
        state.isLoading = val;
      },
    },

    getters: {
      sidebar: (state) => state.sidebar,

      passengerList: (state) => state.passengerList,

      passengersInCourse: (_, getters, _2, rootGetters) => {
        if (Array.isArray(rootGetters['courseCreation/courseCompany'])) {
          const passengers = [];

          rootGetters['courseCreation/courseCompany'].forEach((company) => {
            passengers.push(...getters.passengerList.filter((passenger) => passenger.company?.id === company.id));
          });

          return passengers;
        }

        return getters.passengerList.filter(
          (passenger) => passenger.company?.id === rootGetters['courseCreation/courseCompany'].id
        );
      },

      passengerListLoading: (state) => state.passengerListLoading,

      abstractPlacesList: (state) => state.abstractPlacesList,

      abstractPlacesListLoading: (state) => state.abstractPlacesListLoading,

      hotelList: (state) => state.hotelList,

      hotelListLoading: (state) => state.hotelListLoading,

      companiesList: (state) => state.companiesList,

      transportCompanies: (state) => state.companiesList.filter((c) => c.isTransportCompany === true),

      companiesListLoading: (state) => state.companiesListLoading,

      railwayCompanies: (state) => state.railwayCompanies,

      railwayCompaniesLoading: (state) => state.railwayCompaniesLoading,

      loggedCompany: (state) => state.loggedCompany,

      loggedCompanyLoading: (state) => state.loggedCompanyLoading,

      isSuperiorTransportCompany(_, getters, _2, rootGetters) {
        return getters.loggedCompany?.principals?.find(
          ({ principal }) => principal.id === rootGetters['courseCreation/courseCompany'].id
        )?.superior;
      },

      isLoggedCompanyIsSuperiorContractor: (_, getters) => ({ ride }) =>
        getters.loggedCompany?.principals?.find(({ principal }) => principal?.id === ride.company.id)?.superior,

      startingBasesList: (state) => state.startingBasesList,

      startingBasesListLoading: (state) => state.startingBasesListLoading,

      user: (state) => state.user,

      userId: (state) => `/api/users/${state.user.id}`,

      userTopics: (state) => state.user.mercure.subscribe,

      passenger: (_, getters) => getters.passengerList.find((passenger) => passenger.user?.id === getters.user.id),

      passengerId: (_, getters) => getters.passenger?.id,

      isLoggedIn: (state) => !!state.user,

      isAdmin: (state) => (state.user ? state.user.roles.includes(ROLES.ROLE_ADMIN) : false),

      isAccountant: (state) => (state.user ? state.user.roles.includes(ROLES.ROLE_ACCOUNTANT) : false),

      isManager: (state) =>
        state.user ? state.user.roles.includes(ROLES.ROLE_ADMIN) || state.user.roles.includes(ROLES.ROLE_USER) : false,

      isDriver: (state) => (state.user ? state.user.roles.includes(ROLES.ROLE_DRIVER) : false),

      isPassenger: (state) => (state.user ? state.user.roles.includes(ROLES.ROLE_PASSENGER) : false),

      isCompany: (state) =>
        state.user
          ? !!state.user.company &&
            (state.user.roles.includes(ROLES.ROLE_COMPANY) || state.user.roles.includes(ROLES.ROLE_USER_COMPANY))
          : false,
      isHeadOfCompany: (state) =>
        state.user ? !!state.user.company && state.user.roles.includes(ROLES.ROLE_COMPANY) : false,

      isCompanyUser: (_, getters) => getters.isCompany || getters.isPassenger,

      isTransportCompany: (state) =>
        state.user ? !!state.user.company && state.user.roles.includes(ROLES.ROLE_TRANSPORT_COMPANY) : false,

      hasRole: (state) => (role) => (state.user ? state.user.roles.includes(role) : false),
      userHasPermissionToEditCourse: (_, getters) =>
        getters.isManager || getters.isTransportCompany || getters.isDriver,
      userHasPermissionForKilometerCourse: (_, getters) =>
        getters.isPassenger && getters.hasRole(ROLES.ROLE_KILOMETER_COURSE),
      userHasFleetRole: (_, getters) => getters.hasRole(ROLES.ROLE_FLEET),
      isCourseReadonly: (_, getters) => !getters.userHasPermissionToEditCourse,
      position: (state) => state.position,
      getCompanyById: (state) => (id) => state.companiesList.find((company) => company.id === id),
      usersList: (state) => state.usersList,
      usersListLoading: (state) => state.usersListLoading,
      isLoading: (state) => state.isLoading,
    },

    actions: {
      fetchPassengers({ getters, commit }) {
        if (!getters.passengerListLoading && !getters.passengerListLoading.length) {
          commit('setPassengerListLoading', true);
          return Vue.http.get('api/passengers').then(({ data }) => {
            commit('setPassengerList', data['hydra:member']);
            commit('setPassengerListLoading', false);
          });
        }

        return Promise.resolve();
      },

      fetchCompanies({ getters, commit }, isTransportCompany = false) {
        if (!getters.companiesListLoading || !getters.companiesList.length) {
          commit('setCompaniesListLoading', true);
          Vue.http.get(`api/companies${isTransportCompany ? '?isTransportCompany=true' : ''}`).then(({ data }) => {
            commit('setCompaniesList', data['hydra:member']);
            commit('setCompaniesListLoading', false);
          });
        }
      },

      fetchRailwayCompanies({ commit, getters }) {
        if (!getters.railwayCompaniesLoading || !getters.railwayCompanies.length) {
          commit('setRailwayCompaniesLoading', true);
          Vue.http.get(`api/companies?isTransportCompany=false`).then(({ data }) => {
            commit('setRailwayCompanies', data['hydra:member']);
            commit('setRailwayCompaniesLoading', false);
          });
        }
      },

      fetchCompanyById({ commit }, id) {
        commit('setLoggedCompanyLoading', true);
        return Vue.http.get(`api/companies/${id}`).then(({ data }) => {
          commit('setLoggedCompany', data);
          delay(() => commit('setLoggedCompanyLoading', false), 400);
        });
      },

      fetchRailwayCompanyById({ commit }, id) {
        return Vue.http.get(`api/companies/${id}`).then(({ data }) => {
          commit('updateRailwayCompany', data);
        });
      },

      fetchStartingBases({ getters, commit }) {
        if (!getters.startingBasesListLoading || !getters.startingBasesList.length) {
          commit('setStartingBasesListLoading', true);
          Vue.http.get('api/starting_bases').then(({ data }) => {
            commit('setStartingBasesList', data['hydra:member']);
            commit('setStartingBasesListLoading', false);
          });
        }
      },

      async fetchAbstractPlaces({ getters, commit, dispatch }) {
        if (getters.abstractPlacesListLoading || getters.abstractPlacesList.length) {
          return;
        }

        commit('setAbstractPlacesListLoading', true);
        // ? to consider timecomplexity+caching via filter+store
        const { data } = await Vue.http.get(`api/abstract_places?type=hotel,workPlace,home,place`);

        commit('setAbstractPlacesList', data['hydra:member']);
        commit('setAbstractPlacesListLoading', false);

        if (getters.userHasPermissionForKilometerCourse) {
          commit('setUserPositionLoading', true);
          await GeolocationService.getCoordinates()
            .then((res) => {
              commit('saveUserGeolocation', res);
            })
            .finally(() => {
              dispatch('kilometerCourse/calculateDistanceToAbstractPlaces');
              commit('setUserPositionLoading', false);
            });
        }
      },
      calculateDistanceToAbstractPlaces({ getters, commit, dispatch }) {
        if (getters.userHasPermissionForKilometerCourse) {
          commit('setUserPositionLoading', true);
          GeolocationService.getCoordinates()
            .then((res) => {
              commit('saveUserGeolocation', res);
            })
            .finally(() => {
              dispatch('kilometerCourse/calculateDistanceToAbstractPlaces');
              commit('setUserPositionLoading', false);
            });
        }
      },

      addAbstractPlace({ commit, dispatch }, place) {
        commit('addAbstractPlace', place);
        dispatch('calculateDistanceToAbstractPlaces');
      },

      updateAbstractPlace({ commit, dispatch }, place) {
        commit('updateAbstractPlace', place);
        dispatch('calculateDistanceToAbstractPlaces');
      },

      fetchUsers({ getters, commit }) {
        if (!getters.usersListLoading && !getters.usersList.length) {
          commit('setUsersListLoading', true);
          Vue.http
            .get(`api/users`)
            .then(({ data }) => {
              commit('setUsersList', data['hydra:member']);
            })
            .finally(() => {
              commit('setUsersListLoading', false);
            });
        }
      },

      updateUser({ commit }, { id, firstname, surname }) {
        commit('setLoading', true);
        return UserService.updateUser({ userId: id, payload: { firstname, surname } })
          .then(({ data }) => {
            commit('setUser', data);
            return AuthService.refreshToken();
          })
          .then(() => {
            Vue.notify(defaultApiResultManager.then('Dane osobowe zostały zmienione'));
          })
          .finally(() => {
            commit('setLoading', false);
          });
      },

      updateUserEmail({ commit }, { id, email }) {
        commit('setLoading', true);
        return UserService.updateUser({ userId: id, payload: { email } })
          .then(({ data }) => {
            commit('setUser', data);
            return AuthService.refreshToken();
          })
          .then(() => {
            Vue.notify(defaultApiResultManager.then('Adres e-mail został zmieniony'));
          })
          .finally(() => {
            commit('setLoading', false);
          });
      },

      addUserPhone({ commit, getters }, phones) {
        commit('setLoading', true);
        return UserService.updateUser({ userId: getters.user.id, payload: { phones } })
          .then(({ data }) => {
            commit('setUser', data);
            return AuthService.refreshToken();
          })
          .then(() => {
            Vue.notify(defaultApiResultManager.then('Numer telefonu został dodany'));
          })
          .finally(() => {
            commit('setLoading', false);
          });
      },

      editUserPhone({ commit, getters }, phones) {
        commit('setLoading', true);
        return UserService.updateUser({ userId: getters.user.id, payload: { phones } })
          .then(({ data }) => {
            commit('setUser', data);
            return AuthService.refreshToken();
          })
          .then(() => {
            Vue.notify(defaultApiResultManager.then('Numer telefonu został dodany'));
          })
          .finally(() => {
            commit('setLoading', false);
          });
      },

      async addUserHomeplace({ commit, getters, dispatch }, payload) {
        const { user } = getters;
        if (user.home) {
          dispatch('place/softDeletePlace', user.home);
        }

        commit('setLoading', true);

        const home = await dispatch('place/save', {
          place: {
            ...payload,
            id: null,
            name: `${user.firstname} ${user.surname}`,
            user,
            company: user.company ?? null,
          },
          type: ABSTRACT_PLACE_TYPES.HOME,
        });

        return UserService.updateUser({ userId: user.id, payload: { home: home['@id'] } })
          .then(({ data }) => {
            commit('setUser', data);
            return AuthService.refreshToken();
          })
          .then(() => {
            Vue.notify(defaultApiResultManager.then('Miejsce zamieszkania zostało dodane'));
          })
          .finally(() => {
            commit('setLoading', false);
          });
      },

      updatePassword({ commit, getters }, payload) {
        commit('setLoading', true);
        return UserService.updateUser({ userId: getters.user.id, payload })
          .then(() => {
            Vue.notify(defaultApiResultManager.then('Hasło zostało zmienione'));
          })
          .finally(() => {
            commit('setLoading', false);
          });
      },

      requestResetPasswordLink({ commit }, payload) {
        commit('setLoading', true);
        return UserService.requestResetPassword(payload).finally(() => {
          commit('setLoading', false);
        });
      },

      resetPassword({ commit }, payload) {
        commit('setLoading', true);
        return UserService.resetPassword(payload).finally(() => {
          commit('setLoading', false);
        });
      },
    },

    modules: {
      company: CompanyModule,
      invoice: InvoiceModule,
      place: PlaceModule,
      startingBase: StartingBaseModule,
      employeeRequest: EmployeeRequestModule,
      taxiCourse: TaxiCourseModule,
      locomotive: LocomotiveModule,
      courseSettings: CourseSettingsModule,
      courseCreation: CourseCreationModule,
      kilometerCourse: KilometerCourseModule,
      sse: ServerSentEventsModule,
      comment: CommentModule,
      transferRide: TransferRideModule,
      driver: DriverModule,
      passenger: PassengerModule,
      employeeGroup: EmployeeGroup,
      car: CarModule,
      transferProtocol: TransferProtocolModule,
      finSettlement: FinSettlementModule,
    },
  });

export const store = new Vuex.Store(storeConfig());

export const createStore = (initialState = {}) => new Vuex.Store(merge(storeConfig(), initialState));
